// import React from 'react';
// // import { ApolloProvider } from 'react-apollo';
// // import client from './src/apollo/client';
// import { Auth0Provider } from "./src/containers/react-auth0-wrapper";
// import config from "./auth_config.json";
// const React = require('react');
// const Wrapper = require('./src/wrappers/wrapRootElement');
// //
// // const onRedirectCallback = appState => {
// //   window.history.replaceState(
// //     {},
// //     document.title,
// //     appState && appState.targetUrl
// //       ? appState.targetUrl
// //       : window.location.pathname
// //   );
// // };
// //
// exports.wrapRootElement = ({ element }) => {
//   return <Wrapper>{element}</Wrapper>;
// };

import React from 'react';
import WrapRootElement from './src/wrappers/wrapRootElement';

export const wrapRootElement = ({ element }) => <WrapRootElement>{element}</WrapRootElement>;
// export default wrapRootElement;

// exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
//   const { pathname } = location;
//   const scrollToTopRoutes = [`/medlemsfordeler/handelsavtaler`];
//   if (scrollToTopRoutes.indexOf(pathname) !== -1) {
//     return false;
//   }
// };
