import { createContext, useState, useContext, useEffect } from 'react';
import React from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { msalInstance } from '../wrappers/wrapRootElement';
import { tokenRequest } from '../lib/authConfig';

const USER_URL = process.env.GATSBY_GET_USER;
// const USER_PHONE_URL = process.env.NEXT_PUBLIC_GETUSER_PHONE_URL;

const UserStateContext = createContext();

export const UserProvider = ({ children }) => {
  const { accounts, inProgress, instance } = useMsal();
  if (!instance) {
    return null;
  }
  const loggedIn = useIsAuthenticated();
  const [status, setStatus] = useState('loading');
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [firstName, setFirstName] = useState('');

  // const router = useRouter();
  useEffect(() => {
    console.log('useEffect user context triggered');
    // Fetching most recent userObject from localStorage every time the user refreshes the page

    if (localStorage.getItem('userInfo')) {
      const userFromStorage = JSON.parse(localStorage.getItem('userInfo'));
      setUserInfo(userFromStorage);
    }

    if (localStorage.getItem('firstName')) {
      const firstNameFromStorage = JSON.parse(localStorage.getItem('firstName'));
      setFirstName(firstNameFromStorage);
    }
  }, []);

  const updateUserState = newState => {
    setUserInfo(newState);
    localStorage.setItem('userInfo', JSON.stringify({ ...userInfo, ...newState }));
  };

  const getAccessToken = async () => {
    const token = msalInstance
      .acquireTokenSilent({ ...tokenRequest, account: accounts[0] })
      .then(tokenResponse => {
        // Do something with the tokenResponse
        if (tokenResponse && tokenResponse.accessToken) {
          console.log('has token', tokenResponse);
          return tokenResponse.accessToken;
        } else {
          console.log('no token', tokenResponse);
          return null;
        }
      })
      .catch(error => {
        console.log('user.js, 39:', error);
        if (error && accounts && accounts.length > 0) {
          // fallback to interaction when silent call fails
          return msalInstance.acquireTokenRedirect({
            ...tokenRequest,
            account: accounts[0],
          });
        }
      });

    return token;
  };

  const getUser = async () => {
    // if (user) {
    const accessToken = await getAccessToken();
    const result = await fetch(USER_URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        accessToken,
      }),
    })
      .then(response => response.json())
      .catch(err => {
        console.log('71, error:', err);
        return err;
      });

    //localStorage.setItem('userInfo', result);
    // setUserLocalStorage(result);
    if (result && result.data && result.data.user) {
      // console.log('result getUser, user context:', result.data.user);
      setUser(result.data.user);
    }

    if (result && result.data && result.data.firstName) {
      setFirstName(result.data.firstName);
      localStorage.setItem('firstName', JSON.stringify(result.data.firstName));
    }

    if (result && result.data && result.data.isAdmin && !isAdmin) {
      setIsAdmin(result.data.isAdmin);
    }
    setStatus('loaded');
    // } else {
    //   return 'UserId required, please set the initial user object upon successful login.';
    // }
  };

  const state = {
    user,
    setUser,
    getUser,
    getAccessToken,
    isAdmin,
    status,
    userInfo,
    firstName,
    updateUserState,
  };

  return <UserStateContext.Provider value={state}>{children}</UserStateContext.Provider>;
};

export const useUserState = () => {
  const state = useContext(UserStateContext);

  if (state === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }

  return state;
};
