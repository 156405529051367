import React from 'react';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from '../lib/authConfig';
import '../styles/layout.scss';
import { UserProvider } from '../context/user';

export const msalInstance = new PublicClientApplication(msalConfig);

const WrapRootElement = ({ children }) => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback(event => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });
  return (
    <div>
      <MsalProvider instance={msalInstance}>
        <UserProvider>{children}</UserProvider>
      </MsalProvider>
    </div>
  );
};

export default WrapRootElement;
