exports.components = {
  "component---src-containers-article-archive-js": () => import("./../../../src/containers/articleArchive.js" /* webpackChunkName: "component---src-containers-article-archive-js" */),
  "component---src-containers-article-js": () => import("./../../../src/containers/article.js" /* webpackChunkName: "component---src-containers-article-js" */),
  "component---src-containers-course-js": () => import("./../../../src/containers/course.js" /* webpackChunkName: "component---src-containers-course-js" */),
  "component---src-containers-event-js": () => import("./../../../src/containers/event.js" /* webpackChunkName: "component---src-containers-event-js" */),
  "component---src-containers-membership-benefit-js": () => import("./../../../src/containers/membershipBenefit.js" /* webpackChunkName: "component---src-containers-membership-benefit-js" */),
  "component---src-containers-my-page-js": () => import("./../../../src/containers/myPage.js" /* webpackChunkName: "component---src-containers-my-page-js" */),
  "component---src-containers-newsletter-js": () => import("./../../../src/containers/newsletter.js" /* webpackChunkName: "component---src-containers-newsletter-js" */),
  "component---src-containers-page-js": () => import("./../../../src/containers/page.js" /* webpackChunkName: "component---src-containers-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-hjelp-js": () => import("./../../../src/pages/hjelp.js" /* webpackChunkName: "component---src-pages-hjelp-js" */),
  "component---src-pages-nyhetsbrev-avmelding-region-ost-js": () => import("./../../../src/pages/nyhetsbrev-avmelding-region-ost.js" /* webpackChunkName: "component---src-pages-nyhetsbrev-avmelding-region-ost-js" */),
  "component---src-pages-nyhetsbrev-paamelding-region-ost-js": () => import("./../../../src/pages/nyhetsbrev-paamelding-region-ost.js" /* webpackChunkName: "component---src-pages-nyhetsbrev-paamelding-region-ost-js" */)
}

