const isBrowser = typeof window !== 'undefined';

const CLIENT_ID = process.env.GATSBY_PUBLIC_AUTH_CLIENT_ID;
const REDIRECT_URL = process.env.GATSBY_PUBLIC_REDIRECT_URL;
const TENANT = process.env.GATSBY_PUBLIC_TENANT_URL;
const SCOPE_ID = process.env.GATSBY_PUBLIC_SCOPE_GUID;

// Config object to be passed to Msal on creation
export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1A_SIGNUPORSIGNINWITHPHONE',
    forgotPassword: 'b2c_1_reset',
    editProfile: 'b2c_1_edit_profile',
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${TENANT}/${TENANT}/B2C_1A_SIGNUPORSIGNINWITHPHONE`,
    },
    forgotPassword: {
      authority: `https://${TENANT}/${TENANT}/B2C_1A_SIGNUPORSIGNINWITHPHONE`,
    },
    editProfile: {
      authority: `https://${TENANT}/${TENANT}/B2C_1A_SIGNUPORSIGNINWITHPHONE`,
    },
  },
  authorityDomain: `https://${TENANT}`,
};

export const msalConfig = {
  auth: {
    clientId: CLIENT_ID,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: isBrowser ? REDIRECT_URL : 'http://localhost:8888/logg-inn',
    // redirectUri: isBrowser
    //   ? 'http://localhost:4000/'
    //   : 'http://localhost:4000/',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false,
    cache: {
      // cacheLocation: 'sessionStorage',
      // storeAuthStateInCookie: true,
    },
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['openid', 'profile', `https://${TENANT}/${SCOPE_ID}/user_impersonation`],
  // extraQueryParameters: {
  //   ui_locales: 'nb',
  // },
};

export const tokenRequest = {
  scopes: ['openid', 'profile', `https://${TENANT}/${SCOPE_ID}/user_impersonation`],
  // extraQueryParameters: { ui_locales: 'nb' },
};

// Add here the endpoints for MS Graph API services you would like to use.
// export const graphConfig = {
//   graphMeEndpoint: 'https://graph.microsoft-ppe.com/v1.0/me',
// };
